import { useEffect, useState, useRef } from 'react';
import Dropzone from 'react-dropzone';
import { CSVLink } from 'react-csv';

import { DataGrid } from '@mui/x-data-grid';
import { Stack, Button, TextField } from '@mui/material';

import PageTitle from '../../components/PageTitle';
import Snackbar from '../../components/Snackbar';

import { useLoading } from '../../contexts/LoadingContext';

import { getCutters, importCutterCSV } from '../../resource';

const columns = [
  { field: 'order', headerName: 'Sorszám', width: 150 },
  { field: 'cutter', headerName: 'Raktári jelzet', width: 150 }
];

const Cutters = () => {
  const [cutters, setCutters] = useState();
  const [cuttersWithoutOrder, setCuttersWithoutOrder] = useState();
  const [filteredCutters, setFilteredCutters] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [cuttersFetchError, setCuttersFetchError] = useState(false);
  const [cuttersImportError, setCuttersImportError] = useState(false);
  const [successCSVImport, setSuccessCSVImport] = useState(false);

  const loading = useLoading();
  const dropzoneRef = useRef();
  const csvLinkRef = useRef();

  const fetchCutters = async () => {
    loading.showLoading();

    try {
      const fetchedCutters = await getCutters();
      setCutters(fetchedCutters);
      setCuttersWithoutOrder(
        fetchedCutters.map(cutter => ({
          cutter: cutter.cutter
        }))
      );
    } catch (error) {
      setCuttersFetchError(true);
    } finally {
      loading.hideLoading();
    }
  };

  useEffect(() => {
    fetchCutters();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let newCutters;
    if (searchValue) {
      const newFilteredCutters = cutters.filter(cutter => {
        return cutter.cutter.toLowerCase().includes(searchValue.toLowerCase());
      });
      newCutters = newFilteredCutters;
    } else {
      newCutters = cutters;
    }

    setFilteredCutters(newCutters);
  }, [cutters, searchValue]);

  const openFileDialog = () => {
    dropzoneRef.current.open();
  };

  const handleFileChange = async files => {
    loading.showLoading();
    try {
      await importCutterCSV(files[0]);
      setSuccessCSVImport(true);
      fetchCutters();
    } catch (error) {
      setCuttersImportError(true);
    } finally {
      loading.hideLoading();
    }
  };

  const handleSearchValueChange = searchedVal => {
    setSearchValue(searchedVal);
  };

  const handleCuttersFetchErrorSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setCuttersFetchError(false);
  };

  const handleCuttersImportErrorSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setCuttersImportError(false);
  };

  const handleCuttersSuccessImportSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessCSVImport(false);
  };

  const exportCSV = () => {
    csvLinkRef.current.link.click();
  };

  return (
    <>
      <Stack spacing={2} direction="row" justifyContent="space-between">
        <PageTitle title="Jelzetek" />
        <Stack direction="row">
          <Dropzone
            ref={dropzoneRef}
            noClick
            noKeyboard
            noDrag
            onDrop={handleFileChange}
            accept={{
              'text/csv': ['.csv']
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <Button
                  sx={{ height: 40, mr: 1 }}
                  variant="contained"
                  className="new-floor-plan-button"
                  onClick={openFileDialog}
                >
                  Importálás
                </Button>
              </div>
            )}
          </Dropzone>

          {cuttersWithoutOrder?.length > 0 && (
            <Button variant="contained" onClick={exportCSV} sx={{ height: 40 }}>
              Exportálás
              <CSVLink
                style={{ display: 'none' }}
                filename="cutters.csv"
                data={cuttersWithoutOrder}
                ref={csvLinkRef}
              ></CSVLink>
            </Button>
          )}
        </Stack>
      </Stack>

      {filteredCutters && (
        <>
          <TextField
            className="text-input"
            id="outlined-required"
            label="keresés"
            value={searchValue || ''}
            onChange={event => handleSearchValueChange(event.target.value)}
            sx={{ mb: 1, justifySelf: 'center' }}
          />
          <DataGrid
            sx={{
              backgroundColor: '#fff',
              boxShadow: 1,
              height: 371
            }}
            getRowId={data => {
              return data.order;
            }}
            rows={filteredCutters}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableColumnMenu
            disableSelectionOnClick
          />
        </>
      )}

      <Snackbar open={cuttersFetchError} onClose={handleCuttersFetchErrorSnackbarClose} severity="error">
        Nem sikerült lekérni a jelzeteket.
      </Snackbar>

      <Snackbar open={cuttersImportError} onClose={handleCuttersImportErrorSnackbarClose} severity="error">
        Nem sikerült importálni a CSV fájlt.
      </Snackbar>

      <Snackbar open={successCSVImport} onClose={handleCuttersSuccessImportSnackbarClose} severity="success">
        Sikeresen importálta a CSV fájlt.
      </Snackbar>
    </>
  );
};

export default Cutters;
