import { useNavigate, useLocation } from 'react-router-dom';

import { Box, Toolbar, Drawer, List, ListItemButton, ListItemText } from '@mui/material';

const drawerWidth = 296;

const routes = [
  {
    path: '/classification-number',
    label: 'Raktári szakjelek'
  },
  {
    path: '/cutter',
    label: 'Jelzetek'
  },
  {
    path: '/floor-plan',
    label: 'Alaprajzok'
  }
];

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Drawer variant="permanent" sx={{ width: drawerWidth, [`& .MuiDrawer-paper`]: { width: drawerWidth } }}>
      <Toolbar />
      <Box>
        <List>
          {routes.map(route => (
            <ListItemButton
              key={route.path}
              selected={location.pathname === route.path}
              onClick={() => navigate(route.path)}
              sx={{ px: 4 }}
            >
              <ListItemText primary={route.label} />
            </ListItemButton>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
