import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Dropzone from 'react-dropzone';

import { Button, Stack } from '@mui/material';

import PathDrawer from '../../components/PathDrawer';
import PageTitle from '../../components/PageTitle';
import Snackbar from '../../components/Snackbar';

import { useLoading } from '../../contexts/LoadingContext';

import { getFloorPlanWithPoints, importCSV } from '../../resource';

const FloorPlan = () => {
  const [floorPlan, setFloorPlan] = useState();
  const [floorPlanFetchError, setFloorPlanFetchError] = useState(false);
  const [successCSVImport, setSuccessCSVImport] = useState(false);
  const [floorPlanImportError, setFloorPlanImportError] = useState(false);

  const loading = useLoading();
  const dropzoneRef = useRef();
  const params = useParams();

  const fetchFloorPlan = async () => {
    loading.showLoading();

    try {
      const fetchedFloorPlan = await getFloorPlanWithPoints(params.id);
      setFloorPlan(fetchedFloorPlan);
    } catch (error) {
      setFloorPlanFetchError(true);
    } finally {
      loading.hideLoading();
    }
  };

  useEffect(() => {
    fetchFloorPlan();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const handleFileChange = async files => {
    loading.showLoading();
    try {
      await importCSV(params.id, files[0]);
      setSuccessCSVImport(true);
      fetchFloorPlan();
    } catch (error) {
      setFloorPlanImportError(true);
    } finally {
      loading.hideLoading();
    }
  };

  const openFileDialog = () => {
    dropzoneRef.current.open();
  };

  const handleFloorPlanFetchErrorSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setFloorPlanFetchError(false);
  };

  const handleFloorPlanImportErrorSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setFloorPlanImportError(false);
  };

  const handleFloorPlanSuccessImportSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessCSVImport(false);
  };

  return (
    <>
      {floorPlan && (
        <>
          <Stack spacing={2} direction="row" justifyContent="space-between">
            <PageTitle title={floorPlan.floorPlan.name} />
            <Dropzone
              ref={dropzoneRef}
              noClick
              noKeyboard
              noDrag
              onDrop={handleFileChange}
              accept={{
                'text/csv': ['.csv']
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <Button variant="contained" onClick={openFileDialog}>
                    Importálás
                  </Button>
                </div>
              )}
            </Dropzone>
          </Stack>

          <div className="floor-plan-record-body">
            <PathDrawer floorPlanId={params.id} existingNodes={floorPlan.points} existingEdges={floorPlan.edges} />
          </div>
        </>
      )}

      <Snackbar open={floorPlanFetchError} onClose={handleFloorPlanFetchErrorSnackbarClose} severity="error">
        Nem sikerült lekérni az alaprajzot.
      </Snackbar>

      <Snackbar open={floorPlanImportError} onClose={handleFloorPlanImportErrorSnackbarClose} severity="error">
        Nem sikerült importálni a CSV fájlt.
      </Snackbar>

      <Snackbar open={successCSVImport} onClose={handleFloorPlanSuccessImportSnackbarClose} severity="success">
        Sikeresen importálta a CSV fájlt.
      </Snackbar>
    </>
  );
};

export default FloorPlan;
