import { Stack, Typography, Button } from '@mui/material';

import Modal from '../Modal';

import labels from '../../constants/labels';

const FloorPlanDeleteModal = ({ onClose, onAccept, name, error }) => (
  <Modal>
    <Stack spacing={2}>
      <Typography component="h4" variant="h6" alignSelf="center">
        {labels.floorPlanDelete}
      </Typography>
      <Typography component="span" variant="span" alignSelf="center">
        Biztosan törli a(z) "{name}" alaprajzot?
      </Typography>
      {error && (
        <Typography component="span" variant="span" alignSelf="center" color="red">
          Valami hiba történt!
        </Typography>
      )}
      <Stack direction="row" justifyContent="space-evenly">
        <Button onClick={onAccept} variant="contained">
          {labels.delete}
        </Button>
        <Button onClick={onClose}>{labels.cancel}</Button>
      </Stack>
    </Stack>
  </Modal>
);

export default FloorPlanDeleteModal;
