import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Stack, Button } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import FloorPlanUploadModal from '../../components/FloorPlanUploadModal';
import FloorPlanDeleteModal from '../../components/FloorPlanDeleteModal';
import PageTitle from '../../components/PageTitle';
import Snackbar from '../../components/Snackbar';

import { useLoading } from '../../contexts/LoadingContext';

import { getFloorPlans, createFloorPlan, updateFloorPlan, deleteFloorPlan } from '../../resource';

const FloorPlans = () => {
  const [floorPlans, setFloorPlans] = useState();
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [floorPlanForEdit, setFloorPlanForEdit] = useState();
  const [floorPlanForDelete, setFloorPlanForDelete] = useState();
  const [snackbarText, setSnackbarText] = useState();
  const [successSnackbarCreateText, setSuccessSnackbarCreateText] = useState();
  const [errorDuringSave, setErrorDuringSave] = useState(false);
  const [errorDuringDelete, setErrorDuringDelete] = useState(false);
  const [floorPlansFetchError, setFloorPlansFetchError] = useState(false);

  const navigate = useNavigate();
  const loading = useLoading();

  const fetchFloorPlans = async () => {
    loading.showLoading();

    try {
      const fetchedFloorPlans = await getFloorPlans();
      setFloorPlans(fetchedFloorPlans);
    } catch (error) {
      setFloorPlansFetchError(true);
    } finally {
      loading.hideLoading();
    }
  };

  useEffect(() => {
    fetchFloorPlans();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleViewClick = floorPlanId => {
    navigate(`/floor-plan/${floorPlanId}`);
  };

  const handleEditClick = floorPlan => {
    setFloorPlanForEdit(floorPlan);
  };

  const handleFloorPlanDeleteClick = floorPlanId => {
    setFloorPlanForDelete(floorPlanId);
  };

  const handleNewFloorPlanClick = () => {
    setUploadModalOpen(true);
  };

  const handleUploadModalClose = () => {
    setUploadModalOpen(false);
    setErrorDuringSave(false);
    setFloorPlanForEdit();
  };

  const handleUploadModalSave = async (file, name, deleteExistingNodes) => {
    loading.showLoading();

    try {
      if (floorPlanForEdit) {
        await updateFloorPlan(floorPlanForEdit.id, file, name, deleteExistingNodes);
      } else {
        await createFloorPlan(file, name);
      }

      setSuccessSnackbarCreateText(`A(z) "${name}" alaprajz sikeresen létre lett hozva.`);
      setErrorDuringSave(false);
      setUploadModalOpen(false);
      setFloorPlanForEdit();
      fetchFloorPlans();
    } catch (error) {
      setErrorDuringSave(true);
    } finally {
      loading.hideLoading();
    }
  };

  const handleDeleteModalClose = () => {
    setFloorPlanForDelete();
    setErrorDuringDelete(false);
  };

  const handleDeleteModalAccept = async () => {
    loading.showLoading();

    try {
      await deleteFloorPlan(floorPlanForDelete.id);
      setFloorPlanForDelete();
      fetchFloorPlans();
      setSnackbarText(floorPlanForDelete.name);
      setErrorDuringDelete(false);
    } catch (error) {
      setErrorDuringDelete(true);
    } finally {
      loading.hideLoading();
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarText();
  };

  const handleFloorPlansFetchErrorSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setFloorPlansFetchError(false);
  };

  const handleSuccessSnackBarCreationClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessSnackbarCreateText();
  };

  return (
    <>
      <Stack spacing={2} direction="row" justifyContent="space-between">
        <PageTitle title="Alaprajzok" />
        <Button
          sx={{ height: 40 }}
          variant="contained"
          className="new-floor-plan-button"
          onClick={handleNewFloorPlanClick}
        >
          Új létrehozása
        </Button>
      </Stack>

      {(uploadModalOpen || floorPlanForEdit) && (
        <FloorPlanUploadModal
          error={errorDuringSave}
          onClose={handleUploadModalClose}
          onSave={handleUploadModalSave}
          floorPlanForEdit={floorPlanForEdit}
        />
      )}
      {floorPlanForDelete && (
        <FloorPlanDeleteModal
          error={errorDuringDelete}
          onClose={handleDeleteModalClose}
          onAccept={handleDeleteModalAccept}
          name={floorPlanForDelete.name}
        />
      )}

      {floorPlans && (
        <TableContainer component={Paper}>
          <Table className="table" aria-label="simple table">
            <TableBody>
              {floorPlans.map(floorPlan => (
                <TableRow key={floorPlan.id}>
                  <TableCell align="left">{floorPlan.name}</TableCell>
                  <TableCell align="right">
                    <MapIcon
                      sx={{ cursor: 'pointer', marginRight: 2 }}
                      onClick={() => {
                        handleViewClick(floorPlan.id);
                      }}
                    />
                    <EditIcon onClick={() => handleEditClick(floorPlan)} sx={{ cursor: 'pointer', marginRight: 2 }} />
                    <DeleteOutlineIcon
                      onClick={() => handleFloorPlanDeleteClick(floorPlan)}
                      sx={{ cursor: 'pointer' }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Snackbar open={!!snackbarText} onClose={handleSnackbarClose} severity="success">
        A(z) "{snackbarText}" alaprajz sikeresen törölve lett!
      </Snackbar>

      <Snackbar open={!!successSnackbarCreateText} onClose={handleSuccessSnackBarCreationClose} severity="success">
        {successSnackbarCreateText}
      </Snackbar>

      <Snackbar open={floorPlansFetchError} onClose={handleFloorPlansFetchErrorSnackbarClose} severity="error">
        Nem sikerült lekérni az alaprajzokat.
      </Snackbar>
    </>
  );
};

export default FloorPlans;
