import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Box } from '@mui/material';

import WeightedGraph from './WeightedGraph.js';

import { AppConfigContext } from '../../contexts/AppConfigContext';

import { getPaths } from '../../resource';

import nodeTypes from '../../constants/nodeTypes.js';

const graph = new WeightedGraph();
const Viewer = () => {
  const [paths, setPaths] = useState();
  const [nodes, setNodes] = useState();
  const [imageDimensions, setImageDimensions] = useState();
  const [floorPlanId, setFloorPlanId] = useState();
  const [isEmptyMap, setEmptyMap] = useState(false);

  const location = useLocation();
  const { baseUrl } = useContext(AppConfigContext);

  useEffect(() => {
    (async () => {
      let queries = queryString.parse(location.search);

      const requestBody = {
        location: queries.location,
        collection: queries.collection,
        classificationNumber: queries.classificationNumber,
        cutter: queries.cutter,
        highlighting: queries.highlighting,
        languageCodes: Array.isArray(queries.languageCodes)
          ? queries.languageCodes
          : queries.languageCodes
          ? [queries.languageCodes]
          : []
      };

      try {
        const fetchedPaths = await getPaths(requestBody);

        if (!fetchedPaths.floorPlans.length) {
          setEmptyMap(true);
          return;
        }

        const fetchedNodes = fetchedPaths.routePointsWithEdges.routePoints;

        fetchedNodes.forEach(node => {
          graph.addVertex(node.routePointId.toString());
        });
        fetchedPaths.routePointsWithEdges.edges.forEach(edge => {
          graph.addEdge(edge.source.toString(), edge.target.toString(), 1);
        });

        const startAndShelfNodes = [];
        const newPaths = [];
        fetchedPaths.startPoints.forEach(startPoint => {
          if (startPoint.floorPlanId === fetchedPaths.floorPlans[0].id) {
            const startNodeWithCoords = fetchedPaths.routePointsWithEdges.routePoints.find(
              routePoint => routePoint.routePointId === startPoint.routePointId
            );
            startNodeWithCoords.type = nodeTypes.START_NODE;
            startAndShelfNodes.push(startNodeWithCoords);

            fetchedPaths.floorPlans[0].routePointIds.forEach(routePointId => {
              const shelfNodeWithCoords = fetchedPaths.routePointsWithEdges.routePoints.find(
                routePoint => routePoint.routePointId === routePointId
              );
              shelfNodeWithCoords.type = nodeTypes.SHELF_NODE;
              startAndShelfNodes.push(shelfNodeWithCoords);

              const ids = graph.Dijkstra(startPoint.routePointId.toString(), routePointId.toString());

              const newLines = [];
              ids.forEach((id, index) => {
                if (index === ids.length - 1) {
                  return;
                }

                // eslint-disable-next-line eqeqeq
                const affectedNode = fetchedNodes.find(node => node.routePointId == id);
                // eslint-disable-next-line eqeqeq
                const nextAffectedNode = fetchedNodes.find(node => node.routePointId == ids[index + 1]);

                newLines.push({
                  x1: affectedNode.x,
                  y1: affectedNode.y,
                  x2: nextAffectedNode.x,
                  y2: nextAffectedNode.y
                });
              });
              newPaths.push(newLines);
            });
          }
        });

        setNodes(startAndShelfNodes);
        setPaths(newPaths);
        setFloorPlanId(fetchedPaths.floorPlans[0].id);
      } catch (error) {
        setEmptyMap(true);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isEmptyMap) {
    const parentWindow = window.parent;
    parentWindow.postMessage({ isEmptyMap }, '*');

    return <Box sx={{ padding: 1, fontSize: 16 }}>Vegye fel a kapcsolatot a könyvtárral!</Box>;
  }

  if (!paths) {
    return null;
  }

  const onImgLoad = ({ target: img }) => {
    setImageDimensions({
      width: img.offsetWidth,
      height: img.offsetHeight
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
      }}
    >
      <div
        style={{
          position: 'relative',
          display: 'inline-block'
        }}
      >
        <img
          alt={floorPlanId}
          src={`${baseUrl}/floor-plan-media/${floorPlanId}`}
          style={{ height: 'calc(100vh - 4px)' }}
          onLoad={onImgLoad}
        />

        {imageDimensions && (
          <>
            <svg
              width={imageDimensions.width}
              height={imageDimensions.height}
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                height: 'calc(100vh - 4px)',
                width: imageDimensions.width
              }}
            >
              <defs>
                <marker
                  id="triangle"
                  viewBox="0 0 10 10"
                  refX="11"
                  refY="5"
                  markerUnits="strokeWidth"
                  markerWidth="4"
                  markerHeight="4"
                  orient="auto"
                >
                  <path d="M 0 0 L 10 5 L 0 10 z" fill="#1e6ad1" />
                </marker>
              </defs>

              {paths.map(lines => {
                return lines.map((line, index) => {
                  return (
                    <line
                      key={paths.length + index}
                      x1={(imageDimensions.width / 100) * line.x1}
                      y1={(imageDimensions.height / 100) * line.y1}
                      x2={(imageDimensions.width / 100) * line.x2}
                      y2={(imageDimensions.height / 100) * line.y2}
                      strokeWidth="6"
                      stroke="#1e6ad1"
                      strokeLinecap="round"
                    />
                  );
                });
              })}

              {paths.map(lines => {
                return lines.map((line, index) => {
                  return (
                    <line
                      key={paths.length + index + lines.length}
                      x1={(imageDimensions.width / 100) * line.x1}
                      y1={(imageDimensions.height / 100) * line.y1}
                      x2={(imageDimensions.width / 100) * line.x2}
                      y2={(imageDimensions.height / 100) * line.y2}
                      strokeWidth="4"
                      stroke="#00b0ff"
                      strokeLinecap="round"
                      markerEnd={index === lines.length - 1 ? 'url(#triangle)' : ''}
                    />
                  );
                });
              })}
            </svg>

            <div
              style={{
                // height: imageDimensions.height,
                position: 'absolute',
                left: 0,
                top: 0,
                height: 'calc(100vh - 4px)',
                width: imageDimensions.width
              }}
            >
              {nodes.map((node, index) => {
                return (
                  <>
                    <div
                      key={node.routePointId}
                      style={{
                        position: 'absolute',
                        top: `${node.y}%`,
                        left: `${node.x}%`,
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '10px',

                        border: '3px solid black',
                        backgroundColor: '#fff',
                        width: '8px',
                        height: '8px'
                      }}
                    />

                    {node.type.name === nodeTypes.SHELF_NODE.name && (
                      <div
                        key={node.routePointId + index}
                        style={{
                          position: 'absolute',
                          top: `${node.y}%`,
                          left: `${node.x}%`,
                          transform: 'translate(-50%, -50%)',
                          borderRadius: '10px',

                          backgroundColor: '#000',
                          width: '6px',
                          height: '6px'
                        }}
                      />
                    )}
                  </>
                );
              })}
            </div>
          </>
        )}
      </div>
    </Box>
  );
};

export default Viewer;
