import axios from 'axios';

const api = axios.create({
  baseURL: '/api'
});

const external = axios.create({
  baseURL: ''
});

const setApiHeader = token => {
  api.defaults.headers.Authorization = token;
};

const clearApiHeader = () => {
  delete api.defaults.headers.Authorization;
};

export { api, external, setApiHeader, clearApiHeader };
