import { useEffect, useState, useRef } from 'react';
import Dropzone from 'react-dropzone';
import { CSVLink } from 'react-csv';

import { DataGrid } from '@mui/x-data-grid';
import { Stack, Button, TextField } from '@mui/material';

import PageTitle from '../../components/PageTitle';
import Snackbar from '../../components/Snackbar';

import { useLoading } from '../../contexts/LoadingContext';

import { getClassificationNumbers, importClassificationNumberCSV } from '../../resource';

const columns = [
  { field: 'order', headerName: 'Sorszám', width: 150 },
  { field: 'classificationNumber', headerName: 'Raktári szakjel', width: 150 }
];

const ClasificationNumber = () => {
  const [classificationNumbers, setClassificationNumbers] = useState();
  const [classificationNumbersWithoutOrder, setClassificationNumbersWithoutOrder] = useState();
  const [filteredClassificationNumbers, setFilteredClassificationNumbers] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [classificationNumbersFetchError, setClassificationNumbersFetchError] = useState(false);
  const [classificationNumbersImportError, setClassificationNumbersImportError] = useState(false);
  const [successCSVImport, setSuccessCSVImport] = useState(false);

  const loading = useLoading();
  const dropzoneRef = useRef();
  const csvLinkRef = useRef();

  const fetchClassificationNumbers = async () => {
    loading.showLoading();

    try {
      const fetchedClassificationNumbers = await getClassificationNumbers();
      setClassificationNumbers(fetchedClassificationNumbers);
      setClassificationNumbersWithoutOrder(
        fetchedClassificationNumbers.map(classificationNumber => ({
          classification_number: classificationNumber.classificationNumber
        }))
      );
    } catch (error) {
      setClassificationNumbersFetchError(true);
    } finally {
      loading.hideLoading();
    }
  };

  useEffect(() => {
    fetchClassificationNumbers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let newClassificationNumbers;
    if (searchValue) {
      const newFilteredClassificationNumbers = classificationNumbers.filter(classificationNumber => {
        return classificationNumber.classificationNumber.toLowerCase().includes(searchValue.toLowerCase());
      });
      newClassificationNumbers = newFilteredClassificationNumbers;
    } else {
      newClassificationNumbers = classificationNumbers;
    }

    setFilteredClassificationNumbers(newClassificationNumbers);
  }, [classificationNumbers, searchValue]);

  const openFileDialog = () => {
    dropzoneRef.current.open();
  };

  const handleFileChange = async files => {
    loading.showLoading();
    try {
      await importClassificationNumberCSV(files[0]);
      setSuccessCSVImport(true);
      fetchClassificationNumbers();
    } catch (error) {
      setClassificationNumbersImportError(true);
    } finally {
      loading.hideLoading();
    }
  };

  const handleSearchValueChange = searchedVal => {
    setSearchValue(searchedVal);
  };

  const handleClassificationNumbersFetchErrorSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setClassificationNumbersFetchError(false);
  };

  const handleClassificationNumbersImportErrorSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setClassificationNumbersImportError(false);
  };

  const handleClassificationNumbersSuccessImportSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessCSVImport(false);
  };

  const exportCSV = () => {
    csvLinkRef.current.link.click();
  };

  return (
    <>
      <Stack spacing={2} direction="row" justifyContent="space-between">
        <PageTitle title="Raktári szakjelek" />
        <Stack direction="row">
          <Dropzone
            ref={dropzoneRef}
            noClick
            noKeyboard
            noDrag
            onDrop={handleFileChange}
            accept={{
              'text/csv': ['.csv']
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <Button
                  sx={{ height: 40, mr: 1 }}
                  variant="contained"
                  className="new-floor-plan-button"
                  onClick={openFileDialog}
                >
                  Importálás
                </Button>
              </div>
            )}
          </Dropzone>

          {classificationNumbersWithoutOrder?.length > 0 && (
            <Button variant="contained" onClick={exportCSV} sx={{ height: 40 }}>
              Exportálás
              <CSVLink
                style={{ display: 'none' }}
                filename="classification-numbers.csv"
                data={classificationNumbersWithoutOrder}
                ref={csvLinkRef}
              ></CSVLink>
            </Button>
          )}
        </Stack>
      </Stack>

      {filteredClassificationNumbers && (
        <>
          <TextField
            className="text-input"
            id="outlined-required"
            label="keresés"
            value={searchValue || ''}
            onChange={event => handleSearchValueChange(event.target.value)}
            sx={{ mb: 1, justifySelf: 'center' }}
          />
          <DataGrid
            sx={{
              backgroundColor: '#fff',
              boxShadow: 1,
              height: 371
            }}
            getRowId={data => {
              return data.order;
            }}
            rows={filteredClassificationNumbers}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableColumnMenu
            disableSelectionOnClick
          />
        </>
      )}

      <Snackbar
        open={classificationNumbersFetchError}
        onClose={handleClassificationNumbersFetchErrorSnackbarClose}
        severity="error"
      >
        Nem sikerült Lekérni a raktári szakjeleket.
      </Snackbar>

      <Snackbar
        open={classificationNumbersImportError}
        onClose={handleClassificationNumbersImportErrorSnackbarClose}
        severity="error"
      >
        Nem sikerült importálni a CSV fájlt.
      </Snackbar>

      <Snackbar
        open={successCSVImport}
        onClose={handleClassificationNumbersSuccessImportSnackbarClose}
        severity="success"
      >
        Sikeresen importálta a CSV fájlt.
      </Snackbar>
    </>
  );
};

export default ClasificationNumber;
