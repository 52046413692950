import { api } from './instances';

const getCutters = () => api.get('/stock-sign/cutters').then(response => response.data);

const getClassificationNumbers = () => api.get('/stock-sign/classification-numbers').then(response => response.data);

const importCutterCSV = file => {
  const formData = new FormData();
  formData.append('file', file);

  return api.post('/stock-sign/cutters', formData);
};

const importClassificationNumberCSV = file => {
  const formData = new FormData();
  formData.append('file', file);

  return api.post('/stock-sign/classification-numbers', formData);
};

export { getCutters, getClassificationNumbers, importCutterCSV, importClassificationNumberCSV };
