import { useState, useRef, useMemo, useEffect, useContext } from 'react';

import {
  Menu,
  MenuItem,
  TextField,
  Button,
  Modal,
  Box,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  Tabs,
  Tab
} from '@mui/material';
import { Stack } from '@mui/system';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import Snackbar from '../../components/Snackbar';

import { AppConfigContext } from '../../contexts/AppConfigContext';

import { getCutters, getClassificationNumbers } from '../../resource';

import {
  addRouteNode,
  deleteRouteNode,
  updateNodePosition,
  addStartNode,
  addEdge,
  deleteEdge,
  updateStartNodeInfo,
  addShelfNode,
  addHighlightedShelfNode,
  updateShelfNode,
  updateHighlightedShelfNode,
  getShelfNodeInfoList,
  getHighlightedShelfNodeInfoList
} from '../../resource';

import nodeTypes from '../../constants/nodeTypes.js';

const PathDrawer = ({ floorPlanId, existingNodes, existingEdges }) => {
  const [imageDimensions, setImageDimensions] = useState();
  const [nodes, setNodes] = useState(existingNodes || []);
  const [activeNode, setActiveNode] = useState();
  const [activeLine, setActiveLine] = useState();
  const [isError, setError] = useState(false);
  const [edges, setEdges] = useState(() => {
    return (
      existingEdges?.map(edge => {
        const sourceType = existingNodes.find(node => node.id === edge.source)?.type;
        const targetType = existingNodes.find(node => node.id === edge.target)?.type;
        const affectedTypes = [sourceType, targetType];

        return { ...edge, affectedTypes };
      }) || []
    );
  });
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [nodeMenuAnchorEl, setNodeMenuAnchorEl] = useState(null);
  const [edgeMenuAnchorEl, setEdgeMenuAnchorEl] = useState(null);
  const [startNodeModalOpen, setStartNodeModalOpen] = useState(false);
  const [shelfNodeModalOpen, setShelfNodeModalOpen] = useState(false);
  const [highlightedShelfNodeModalOpen, setHighlightedShelfNodeModalOpen] = useState(false);
  const [clickPosition, setClickPosition] = useState({});
  const [nodeNameValue, setNodeNameValue] = useState('');
  const [isNodeNameError, setNodeNameError] = useState(false);
  const [nodeIpValue, setNodeIpValue] = useState('');
  const [shelfNodeIdsToDelete, setShelfNodeIdsToDelete] = useState();
  const [highlightedShelfNodeIdsToDelete, setHighlightedShelfNodeIdsToDelete] = useState();

  const [classificationNumbers, setClassificationNumbers] = useState([]);
  const [cutters, setCutters] = useState([]);

  const [shelfNodeInformation, setShelfNodeInformation] = useState([]);
  const [highlightedShelfNodeInformation, setHighlightedShelfNodeInformation] = useState([]);
  const [activeShelfNodeInfoTab, setActiveShelfNodeInfoTab] = useState(0);
  const [activeHighlightedShelfNodeInfoTab, setActiveHighlightedShelfNodeInfoTab] = useState(0);

  const [locationErrorIndexes, setLocationErrorIndexes] = useState([]);
  const [collectionErrorIndexes, setCollectionErrorIndexes] = useState([]);
  const [classificationOrCutterErrorIndexes, setClassificationOrCutterErrorIndexes] = useState([]);
  const [locationErrorIndexesForHighlightedEntity, setLocationErrorIndexesForHighlightedEntity] = useState([]);
  const [collectionErrorIndexesForHighlightedEntity, setCollectionErrorIndexesForHighlightedEntity] = useState([]);
  const [highlightingErrorIndexesForHighlightedEntity, setHighlightingErrorIndexesForHighlightedEntity] = useState([]);

  const [areRouteNodesVisible, setRouteNodesVisible] = useState(true);
  const [areStartNodesVisible, setStartNodesVisible] = useState(true);
  const [areShelfNodesVisible, setShelfNodesVisible] = useState(true);

  const parentRef = useRef(null);
  const rightClickDivRef = useRef(null);

  const { baseUrl } = useContext(AppConfigContext);

  useEffect(() => {
    (async () => {
      const fetchedClassificationNumbers = await getClassificationNumbers();
      const fetchedCutters = await getCutters();

      setClassificationNumbers(
        fetchedClassificationNumbers.map(
          fetchedClassificationNumber => fetchedClassificationNumber.classificationNumber
        )
      );
      setCutters(fetchedCutters.map(fetchedCutter => fetchedCutter.cutter));
    })();
  }, []);

  useEffect(() => {
    if (existingNodes) {
      setNodes(existingNodes);
    }
  }, [existingNodes]);

  useEffect(() => {
    if (!shelfNodeInformation.length) {
      setActiveShelfNodeInfoTab(0);
    }
  }, [shelfNodeInformation]);

  useEffect(() => {
    if (!highlightedShelfNodeInformation.length) {
      setActiveHighlightedShelfNodeInfoTab(0);
    }
  }, [highlightedShelfNodeInformation]);

  const lines = useMemo(() => {
    const newLines = [];

    edges.forEach(edge => {
      const sourceNode = nodes.find(node => node.id === edge.source);
      const targetNode = nodes.find(node => node.id === edge.target);

      if (sourceNode && targetNode) {
        newLines.push({
          x1: sourceNode.x,
          y1: sourceNode.y,
          x2: targetNode.x,
          y2: targetNode.y,
          id: edge.id
        });
      }
    });

    return newLines;
  }, [nodes, edges]);

  useEffect(() => {
    if (clickPosition) {
      setMenuAnchorEl(rightClickDivRef.current);
    }
  }, [clickPosition]);

  const getClickPosition = event => {
    const bounds = parentRef.current.getBoundingClientRect();
    const x = ((event.clientX - bounds.left) * 100) / imageDimensions.width;
    const y = ((event.clientY - bounds.top) * 100) / imageDimensions.height;

    return { x, y };
  };

  const handleImageLoad = ({ target: img }) => {
    setImageDimensions({
      width: img.offsetWidth,
      height: img.offsetHeight
    });
  };

  const handleMenuClose = () => {
    setClickPosition();
    setMenuAnchorEl();
  };

  const handleNodeMenuClose = () => {
    setActiveNode();
    setNodeMenuAnchorEl();
  };

  const handleEdgeMenuClose = () => {
    setActiveLine();
    setEdgeMenuAnchorEl();
  };

  const handleStartNodeModalClose = () => {
    setStartNodeModalOpen(false);
    setActiveNode();
    setNodeNameValue('');
    setNodeIpValue('');
    setNodeNameError(false);
  };

  const handleShelfNodeModalClose = () => {
    setShelfNodeInformation([]);
    setShelfNodeModalOpen(false);
    setActiveNode();
    setLocationErrorIndexes([]);
    setCollectionErrorIndexes([]);
    setClassificationOrCutterErrorIndexes([]);
  };

  const handleHighlightedShelfNodeModalClose = () => {
    setHighlightedShelfNodeInformation([]);
    setHighlightedShelfNodeModalOpen(false);
    setActiveNode();
    setLocationErrorIndexesForHighlightedEntity([]);
    setCollectionErrorIndexesForHighlightedEntity([]);
    setHighlightingErrorIndexesForHighlightedEntity([]);
  };

  const handleNodeDeleteMenuClick = async event => {
    event.preventDefault();
    event.stopPropagation();

    try {
      await deleteRouteNode(floorPlanId, activeNode?.id);

      const newNodes = nodes.filter(currentNode => currentNode.id !== activeNode.id);

      const newEdges = edges.filter(edge => edge.source !== activeNode.id && edge.target !== activeNode.id);

      handleNodeMenuClose();
      setNodes(newNodes);
      setEdges(newEdges);
    } catch (error) {
      setError(true);
    }
  };

  const handleStartNodeEditClick = event => {
    event.preventDefault();
    event.stopPropagation();

    setNodeMenuAnchorEl();
    setStartNodeModalOpen(true);
    setNodeIpValue(activeNode.startPointInfo.ipAddress);
    setNodeNameValue(activeNode.startPointInfo.name);
  };

  const handleShelfNodeEditClick = async event => {
    event.preventDefault();
    event.stopPropagation();

    setShelfNodeIdsToDelete([]);
    const shelfNodeInfoList = await getShelfNodeInfoList(floorPlanId, activeNode.id);
    setShelfNodeInformation(shelfNodeInfoList);
    setNodeMenuAnchorEl();
    setShelfNodeModalOpen(true);
  };

  const handleHighlightedShelfNodeEditClick = async event => {
    event.preventDefault();
    event.stopPropagation();

    setHighlightedShelfNodeIdsToDelete([]);
    const highlightedShelfNodeInfoList = await getHighlightedShelfNodeInfoList(floorPlanId, activeNode.id);
    setHighlightedShelfNodeInformation(highlightedShelfNodeInfoList);
    setNodeMenuAnchorEl();
    setHighlightedShelfNodeModalOpen(true);
  };

  const handleEdgeDeleteMenuClick = async event => {
    event.preventDefault();
    event.stopPropagation();

    try {
      await deleteEdge(floorPlanId, activeLine.id);

      const newEdges = edges.filter(edge => edge.id !== activeLine.id);

      setEdgeMenuAnchorEl();
      setEdges(newEdges);
    } catch (error) {
      setError(true);
    }
  };

  const handleLeftClickOnMap = event => {
    if (menuAnchorEl) {
      handleMenuClose();
      return;
    }
    if (edgeMenuAnchorEl) {
      handleEdgeMenuClose();
      return;
    }
    if (nodeMenuAnchorEl) {
      handleNodeMenuClose();
      return;
    }

    const { x, y } = getClickPosition(event);

    if (activeNode) {
      modifyNodePosition(activeNode.id, { x, y });
    } else {
      addNode({ x, y }, nodeTypes.ROUTE_NODE.name);
    }
  };

  const handleRightClickOnMap = event => {
    event.preventDefault();

    if (menuAnchorEl) {
      handleMenuClose();
    } else if (nodeMenuAnchorEl) {
      handleNodeMenuClose();
    } else if (edgeMenuAnchorEl) {
      handleEdgeMenuClose();
    } else if (activeNode) {
      setActiveNode();
    } else if (activeLine) {
      setActiveLine();
    } else {
      const { x, y } = getClickPosition(event);

      setClickPosition({ x, y });
      // setMenuAnchorEl(event.currentTarget);
    }
  };

  const handleLeftClickOnNode = async (event, node) => {
    event.preventDefault();
    event.stopPropagation();

    if (activeNode?.id === node.id) {
      setActiveNode();
    } else if (activeNode) {
      const isEdgeExists = edges.some(
        edge =>
          (edge.source === activeNode.id && edge.target === node.id) ||
          (edge.source === node.id && edge.target === activeNode.id)
      );

      setActiveNode();
      if (!isEdgeExists) {
        try {
          const newEdge = await addEdge(floorPlanId, { source: activeNode.id, target: node.id });

          setEdges(prevEdges => [
            ...prevEdges,
            {
              source: activeNode.id,
              target: node.id,
              id: newEdge.id,
              affectedTypes: [activeNode.type, node.type]
            }
          ]);
        } catch (error) {
          setError(true);
        }
      }
    } else {
      setActiveNode(node);
    }
  };

  const handleRightClickOnNode = (event, node) => {
    event.preventDefault();
    event.stopPropagation();

    setActiveNode(node);
    setNodeMenuAnchorEl(event.currentTarget);
  };

  const handleLeftClickOnLine = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleRightClickOnLine = (event, line) => {
    event.preventDefault();
    event.stopPropagation();

    setActiveLine(line);
    setEdgeMenuAnchorEl(event.currentTarget);
  };

  const handleMenuRouteNodeClick = event => {
    addNode(clickPosition, nodeTypes.ROUTE_NODE.name);
    handleMenuClose();
  };

  const handleMenuStartNodeClick = event => {
    event.preventDefault();
    event.stopPropagation();

    setMenuAnchorEl();
    setStartNodeModalOpen(true);
  };

  const handleMenuShelfNodeClick = event => {
    event.preventDefault();
    event.stopPropagation();

    setMenuAnchorEl();
    setShelfNodeInformation([{}]);
    setShelfNodeModalOpen(true);
  };

  const handleMenuHighlightedShelfNodeClick = event => {
    event.preventDefault();
    event.stopPropagation();

    setMenuAnchorEl();
    setHighlightedShelfNodeInformation([{}]);
    setHighlightedShelfNodeModalOpen(true);
  };

  const modifyNodePosition = async (nodeId, { x, y }) => {
    try {
      await updateNodePosition(floorPlanId, activeNode.id, { x, y });

      const newNodes = [...nodes];
      const selectedNodeIndex = newNodes.findIndex(currentNode => currentNode.id === nodeId);

      newNodes[selectedNodeIndex].x = x;
      newNodes[selectedNodeIndex].y = y;

      setNodes(newNodes);
      setActiveNode();
    } catch (error) {
      setError(true);
    }
  };

  const saveStartNode = async () => {
    if (activeNode) {
      const requestBody = {
        name: nodeNameValue,
        ipAddress: nodeIpValue || null
      };
      try {
        await updateStartNodeInfo(floorPlanId, activeNode.startPointInfo.id, requestBody);

        const newNodes = [...nodes];
        const newNode = {
          ...activeNode,
          startPointInfo: {
            name: nodeNameValue,
            ipAddress: nodeIpValue,
            id: activeNode.startPointInfo.id
          }
        };
        const activeNodeIndex = nodes.findIndex(node => node.id === activeNode.id);
        newNodes[activeNodeIndex] = newNode;

        setNodes(newNodes);
        setStartNodeModalOpen(false);
        setNodeNameValue('');
        setNodeIpValue('');
        setClickPosition();
        setActiveNode();
      } catch (error) {
        setError(true);
      }
    } else {
      addNode(clickPosition, nodeTypes.START_NODE.name);
    }
  };

  const getInvalidClassificationAndCutterIndexes = shelfNodeInfo => {
    return shelfNodeInfo
      .map((info, index) =>
        (!info.classificationNumberFrom?.length || !info.classificationNumberTo?.length) &&
        (!info.cutterFrom || !info.cutterTo)
          ? index
          : -1
      )
      .filter(index => index !== -1);
  };

  const validateTextInputs = () => {
    const newLocationErrorIndexes = shelfNodeInformation
      .map((info, index) => (!info.location?.length ? index : -1))
      .filter(index => index !== -1);

    if (newLocationErrorIndexes.length) {
      setLocationErrorIndexes(newLocationErrorIndexes);
    }

    const newCollectionErrorIndexes = shelfNodeInformation
      .map((info, index) => (!info.collection?.length ? index : -1))
      .filter(index => index !== -1);

    if (newCollectionErrorIndexes.length) {
      setCollectionErrorIndexes(newCollectionErrorIndexes);
    }

    const newClassificationOrCutterErrorIndexes = getInvalidClassificationAndCutterIndexes(shelfNodeInformation);

    if (newClassificationOrCutterErrorIndexes.length) {
      setClassificationOrCutterErrorIndexes(newClassificationOrCutterErrorIndexes);
    }

    if (
      newLocationErrorIndexes.length ||
      newCollectionErrorIndexes.length ||
      newClassificationOrCutterErrorIndexes.length
    ) {
      return false;
    }

    return true;
  };

  const validateTextInputsForHighlightedShelfNode = () => {
    const newLocationErrorIndexesForHighlightedEntity = highlightedShelfNodeInformation
      .map((info, index) => (!info.location?.length ? index : -1))
      .filter(index => index !== -1);

    if (newLocationErrorIndexesForHighlightedEntity.length) {
      setLocationErrorIndexesForHighlightedEntity(newLocationErrorIndexesForHighlightedEntity);
    }

    const newCollectionErrorIndexesForHighlightedEntity = highlightedShelfNodeInformation
      .map((info, index) => (!info.collection?.length ? index : -1))
      .filter(index => index !== -1);

    if (newCollectionErrorIndexesForHighlightedEntity.length) {
      setCollectionErrorIndexesForHighlightedEntity(newCollectionErrorIndexesForHighlightedEntity);
    }

    const newHighlightingErrorIndexesForHighlightedEntity = highlightedShelfNodeInformation
      .map((info, index) => (!info.highlighting?.length ? index : -1))
      .filter(index => index !== -1);

    if (newHighlightingErrorIndexesForHighlightedEntity.length) {
      setHighlightingErrorIndexesForHighlightedEntity(newHighlightingErrorIndexesForHighlightedEntity);
    }

    if (
      newLocationErrorIndexesForHighlightedEntity.length ||
      newCollectionErrorIndexesForHighlightedEntity.length ||
      newHighlightingErrorIndexesForHighlightedEntity.length
    ) {
      return false;
    }

    return true;
  };

  const saveShelfNode = async () => {
    if (!validateTextInputs()) {
      return;
    }

    if (activeNode) {
      // UPDATE EXISTING SHELF NODE
      const entityInfoUpdateRequest = shelfNodeInformation.map(currentShelfNodeInfo => ({
        id: currentShelfNodeInfo.id,
        location: currentShelfNodeInfo.location?.trim(),
        collection: currentShelfNodeInfo.collection?.trim(),
        classificationNumberFrom: currentShelfNodeInfo.classificationNumberFrom || null,
        cutterFrom: currentShelfNodeInfo.cutterFrom || null,
        classificationNumberTo: currentShelfNodeInfo.classificationNumberTo || null,
        cutterTo: currentShelfNodeInfo.cutterTo || null,
        languageCode: currentShelfNodeInfo.languageCode?.trim() || null,
        coordinatePairs: [
          {
            x1: activeNode.x,
            y1: activeNode.y,
            x2: activeNode.x,
            y2: activeNode.y
          }
        ]
      }));

      const requestBody = {
        entityInfoUpdateRequest,
        entityInfoIdListForDelete: shelfNodeIdsToDelete
      };

      try {
        await updateShelfNode(floorPlanId, requestBody, activeNode.id);

        setShelfNodeModalOpen(false);
        setShelfNodeInformation([]);
        setClickPosition();
        setActiveNode();
      } catch (error) {
        setError(true);
      }
    } else {
      // CREATE NEW SHELF NODE
      const requestBody = shelfNodeInformation.map(currentShelfNodeInformation => ({
        location: currentShelfNodeInformation.location?.trim(),
        collection: currentShelfNodeInformation.collection?.trim(),
        classificationNumberFrom: currentShelfNodeInformation.classificationNumberFrom || null,
        cutterFrom: currentShelfNodeInformation.cutterFrom || null,
        classificationNumberTo: currentShelfNodeInformation.classificationNumberTo || null,
        cutterTo: currentShelfNodeInformation.cutterTo || null,
        languageCode: currentShelfNodeInformation.languageCode?.trim() || null,
        coordinatePairs: [
          {
            x1: clickPosition.x,
            y1: clickPosition.y,
            x2: clickPosition.x,
            y2: clickPosition.y
          }
        ]
      }));

      try {
        const newShelfNode = await addShelfNode(floorPlanId, requestBody);

        setShelfNodeModalOpen(false);
        setShelfNodeInformation([]);
        setClickPosition();

        setNodes(prevNodes => [
          ...prevNodes,
          {
            x: clickPosition.x,
            y: clickPosition.y,
            id: newShelfNode[0].routePointIds[0],
            type: nodeTypes.SHELF_NODE.name
          }
        ]);
      } catch (error) {
        setError(true);
      }
    }
  };

  const saveHighlightedShelfNode = async () => {
    if (!validateTextInputsForHighlightedShelfNode()) {
      return;
    }

    if (activeNode) {
      // UPDATE EXISTING SHELF NODE
      const highlightedEntityInfoUpdateRequest = highlightedShelfNodeInformation.map(currentShelfNodeInfo => ({
        id: currentShelfNodeInfo.id,
        location: currentShelfNodeInfo.location?.trim(),
        collection: currentShelfNodeInfo.collection?.trim(),
        highlighting: currentShelfNodeInfo.highlighting?.trim(),
        coordinatePairs: [
          {
            x1: activeNode.x,
            y1: activeNode.y,
            x2: activeNode.x,
            y2: activeNode.y
          }
        ]
      }));

      const requestBody = {
        highlightedEntityInfoUpdateRequest,
        highlightedEntityInfoIdListForDelete: highlightedShelfNodeIdsToDelete
      };

      try {
        await updateHighlightedShelfNode(floorPlanId, requestBody, activeNode.id);

        setHighlightedShelfNodeModalOpen(false);
        setHighlightedShelfNodeInformation([]);
        setClickPosition();
        setActiveNode();
      } catch (error) {
        setError(true);
      }
    } else {
      // CREATE NEW HIGHLIGHTED SHELF NODE
      const requestBody = highlightedShelfNodeInformation.map(currentHighlightedShelfNodeInformation => ({
        location: currentHighlightedShelfNodeInformation.location?.trim(),
        collection: currentHighlightedShelfNodeInformation.collection?.trim(),
        highlighting: currentHighlightedShelfNodeInformation.highlighting?.trim(),
        coordinatePairs: [
          {
            x1: clickPosition.x,
            y1: clickPosition.y,
            x2: clickPosition.x,
            y2: clickPosition.y
          }
        ]
      }));

      try {
        const newHighlightedShelfNode = await addHighlightedShelfNode(floorPlanId, requestBody);

        setHighlightedShelfNodeModalOpen(false);
        setHighlightedShelfNodeInformation([]);
        setClickPosition();

        setNodes(prevNodes => [
          ...prevNodes,
          {
            x: clickPosition.x,
            y: clickPosition.y,
            id: newHighlightedShelfNode[0].routePointIds[0],
            type: nodeTypes.HIGHLIGHTED_SHELF_NODE.name
          }
        ]);
      } catch (error) {
        setError(true);
      }
    }
  };

  const addNode = async ({ x, y }, nodeType) => {
    let saveFunction;
    let requestBody;

    if (nodeType === nodeTypes.ROUTE_NODE.name) {
      requestBody = { coordinates: { x, y } };
      saveFunction = addRouteNode;
    } else if (nodeType === nodeTypes.START_NODE.name) {
      if (!nodeNameValue?.length) {
        setNodeNameError(true);
        return;
      }

      requestBody = {
        name: nodeNameValue,
        coordinates: { x, y },
        ipAddress: nodeIpValue || null
      };
      saveFunction = addStartNode;
    }

    try {
      const newRouteNode = await saveFunction(floorPlanId, requestBody);

      setStartNodeModalOpen(false);
      setShelfNodeModalOpen(false);
      setHighlightedShelfNodeModalOpen(false);
      setShelfNodeInformation([]);
      setHighlightedShelfNodeInformation([]);
      setNodeNameValue('');
      setNodeIpValue('');
      setClickPosition();

      setNodes(prevNodes => [
        ...prevNodes,
        {
          x,
          y,
          id: newRouteNode.routePointId,
          type: nodeType,
          startPointInfo: {
            name: newRouteNode.name,
            ipAddress: newRouteNode.ipAddress,
            id: newRouteNode.id
          }
        }
      ]);
    } catch (error) {
      setError(true);
    }
  };

  const handleNodeNameChange = value => {
    setNodeNameError(false);
    setNodeNameValue(value);
  };

  const handleShelfNodeInformationChange = (key, value) => {
    if (key === 'location' && locationErrorIndexes.includes(activeShelfNodeInfoTab)) {
      const newLocationErrorIndexes = locationErrorIndexes.filter(
        currentLocationErrorIndex => currentLocationErrorIndex !== activeShelfNodeInfoTab
      );
      setLocationErrorIndexes(newLocationErrorIndexes);
    }

    if (key === 'collection' && collectionErrorIndexes.includes(activeShelfNodeInfoTab)) {
      const newCollectionErrorIndexes = collectionErrorIndexes.filter(
        currentCollectionErrorIndex => currentCollectionErrorIndex !== activeShelfNodeInfoTab
      );
      setCollectionErrorIndexes(newCollectionErrorIndexes);
    }

    const newShelfNodeInformation = [...shelfNodeInformation];
    newShelfNodeInformation[activeShelfNodeInfoTab][key] = value;

    if (
      key === 'classificationNumberFrom' ||
      key === 'cutterFrom' ||
      key === 'classificationNumberTo' ||
      key === 'cutterTo'
    ) {
      const newClassificationOrCutterErrorIndexes = getInvalidClassificationAndCutterIndexes(newShelfNodeInformation);
      setClassificationOrCutterErrorIndexes(newClassificationOrCutterErrorIndexes);
    }

    setShelfNodeInformation(newShelfNodeInformation);
  };

  const handleHighlightedShelfNodeInformationChange = (key, value) => {
    if (key === 'location' && locationErrorIndexesForHighlightedEntity.includes(activeHighlightedShelfNodeInfoTab)) {
      const newLocationErrorIndexes = locationErrorIndexesForHighlightedEntity.filter(
        currentLocationErrorIndex => currentLocationErrorIndex !== activeHighlightedShelfNodeInfoTab
      );
      setLocationErrorIndexesForHighlightedEntity(newLocationErrorIndexes);
    }

    if (
      key === 'collection' &&
      collectionErrorIndexesForHighlightedEntity.includes(activeHighlightedShelfNodeInfoTab)
    ) {
      const newCollectionErrorIndexes = collectionErrorIndexesForHighlightedEntity.filter(
        currentCollectionErrorIndex => currentCollectionErrorIndex !== activeHighlightedShelfNodeInfoTab
      );
      setCollectionErrorIndexesForHighlightedEntity(newCollectionErrorIndexes);
    }

    if (
      key === 'highlighting' &&
      highlightingErrorIndexesForHighlightedEntity.includes(activeHighlightedShelfNodeInfoTab)
    ) {
      const newHighlightingErrorIndexes = highlightingErrorIndexesForHighlightedEntity.filter(
        currentHighlightingErrorIndex => currentHighlightingErrorIndex !== activeHighlightedShelfNodeInfoTab
      );
      setHighlightingErrorIndexesForHighlightedEntity(newHighlightingErrorIndexes);
    }

    const newHighlightedShelfNodeInformation = [...highlightedShelfNodeInformation];
    newHighlightedShelfNodeInformation[activeHighlightedShelfNodeInfoTab][key] = value;

    setHighlightedShelfNodeInformation(newHighlightedShelfNodeInformation);
  };

  const handleShelfNodeInfoDelete = (selectedTabIndex, shelfNodeId) => {
    if (shelfNodeId) {
      const newShelfNodeIdsToDelete = [...shelfNodeIdsToDelete, shelfNodeId];

      setShelfNodeIdsToDelete(newShelfNodeIdsToDelete);
    }

    const newShelfNodeInformation = shelfNodeInformation.filter((_, index) => index !== selectedTabIndex);

    setShelfNodeInformation(newShelfNodeInformation);
    setActiveShelfNodeInfoTab(0);
  };

  const handleHighlightedShelfNodeInfoDelete = (selectedTabIndex, highlightedShelfNodeId) => {
    if (highlightedShelfNodeId) {
      const newHighlightedShelfNodeIdsToDelete = [...highlightedShelfNodeIdsToDelete, highlightedShelfNodeId];

      setHighlightedShelfNodeIdsToDelete(newHighlightedShelfNodeIdsToDelete);
    }

    const newHighlightedShelfNodeInformation = highlightedShelfNodeInformation.filter(
      (_, index) => index !== selectedTabIndex
    );

    setHighlightedShelfNodeInformation(newHighlightedShelfNodeInformation);
    setActiveHighlightedShelfNodeInfoTab(0);
  };

  const addMoreShelfNodeInformation = () => {
    setShelfNodeInformation(prevShelfNodeInformation => [...prevShelfNodeInformation, {}]);
  };

  const addMoreHighlightedShelfNodeInformation = () => {
    setHighlightedShelfNodeInformation(prevHighlightedShelfNodeInformation => [
      ...prevHighlightedShelfNodeInformation,
      {}
    ]);
  };

  const isLineVisible = lineId => {
    const affectedEdge = edges.find(edge => edge.id === lineId);

    const hideBecauseOfRouteNode =
      !areRouteNodesVisible && affectedEdge.affectedTypes.some(type => type === nodeTypes.ROUTE_NODE.name);

    const hideBecauseOfStartNode =
      !areStartNodesVisible && affectedEdge.affectedTypes.some(type => type === nodeTypes.START_NODE.name);

    const hideBecauseOfShelfNode =
      !areShelfNodesVisible &&
      affectedEdge.affectedTypes.some(
        type => type === nodeTypes.SHELF_NODE.name || type === nodeTypes.HIGHLIGHTED_SHELF_NODE.name
      );

    if (hideBecauseOfRouteNode || hideBecauseOfStartNode || hideBecauseOfShelfNode) {
      return false;
    }
    return true;
  };

  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setError(false);
  };

  return (
    <div>
      <div>
        <Stack flexDirection="row">
          <FormControlLabel
            control={
              <Checkbox checked={areRouteNodesVisible} onChange={() => setRouteNodesVisible(!areRouteNodesVisible)} />
            }
            label={nodeTypes.ROUTE_NODE.label}
          />
          <FormControlLabel
            control={
              <Checkbox checked={areStartNodesVisible} onChange={() => setStartNodesVisible(!areStartNodesVisible)} />
            }
            label={nodeTypes.START_NODE.label}
          />
          <FormControlLabel
            control={
              <Checkbox
                label="Shelf point"
                checked={areShelfNodesVisible}
                onChange={() => setShelfNodesVisible(!areShelfNodesVisible)}
              />
            }
            label={nodeTypes.SHELF_NODE.label}
          />
        </Stack>
      </div>

      <div
        style={{
          position: 'relative',
          display: 'inline-block',
          userSelect: 'none'
        }}
        ref={parentRef}
        onClick={handleLeftClickOnMap}
        onContextMenu={handleRightClickOnMap}
      >
        {/* START MODAL FROM */}
        <Modal
          open={startNodeModalOpen}
          onClose={handleStartNodeModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          <Box
            style={{
              borderRadius: '6px',
              backgroundColor: '#fff',
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '16px'
              }}
            >
              <TextField
                error={isNodeNameError}
                required
                id="outlined-required"
                label="Név"
                value={nodeNameValue || ''}
                onChange={event => handleNodeNameChange(event.target.value)}
                sx={{ mb: 1 }}
              />
              <TextField
                id="outlined"
                label="IP-cím"
                value={nodeIpValue || ''}
                onChange={event => setNodeIpValue(event.target.value)}
                sx={{ mb: 1 }}
              />
              <Button style={{ marginTop: '8px' }} variant="contained" onClick={saveStartNode}>
                Mentés
              </Button>
            </div>
          </Box>
        </Modal>

        {/* SHELF MODAL FROM */}
        <Modal
          open={shelfNodeModalOpen}
          onClose={handleShelfNodeModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          <Box
            style={{
              width: '400px',
              borderRadius: '6px',
              backgroundColor: '#fff',
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              padding: '16px'
            }}
          >
            <Stack flexDirection="row" sx={{ mb: 2 }}>
              <Tabs
                value={activeShelfNodeInfoTab}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                {shelfNodeInformation.map((currentShelfNodeInfo, index) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <Tab
                      key={index}
                      onClick={() => setActiveShelfNodeInfoTab(index)}
                      // className={`${formErrors.questions && formErrors.questions[index] === false ? 'invalid' : ''}`}
                      label={index + 1}
                      sx={{
                        color:
                          collectionErrorIndexes.includes(index) ||
                          locationErrorIndexes.includes(index) ||
                          classificationOrCutterErrorIndexes.includes(index)
                            ? '#ff0000'
                            : '#808080'
                      }}
                    />
                    {shelfNodeInformation.length > 1 && (
                      <HighlightOffIcon
                        onClick={() => handleShelfNodeInfoDelete(index, currentShelfNodeInfo.id)}
                        sx={{ color: '#1565c0', width: '1rem', cursor: 'pointer' }}
                      />
                    )}
                  </div>
                ))}
              </Tabs>
              <Button sx={{ ml: 1 }} onClick={addMoreShelfNodeInformation}>
                <AddCircleOutlineIcon />
              </Button>
            </Stack>

            {shelfNodeInformation.length > 0 && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <TextField
                  error={locationErrorIndexes.includes(activeShelfNodeInfoTab)}
                  required
                  id="outlined-required"
                  label="Elhelyezkedés"
                  value={shelfNodeInformation[activeShelfNodeInfoTab].location || ''}
                  onChange={event => handleShelfNodeInformationChange('location', event.target.value)}
                  sx={{ mb: 1 }}
                />
                <TextField
                  error={collectionErrorIndexes.includes(activeShelfNodeInfoTab)}
                  required
                  id="outlined"
                  label="Gyűjtemény"
                  value={shelfNodeInformation[activeShelfNodeInfoTab].collection || ''}
                  onChange={event => handleShelfNodeInformationChange('collection', event.target.value)}
                  sx={{ mb: 1 }}
                />
                <TextField
                  id="outlined"
                  label="Nyelvi kódok"
                  value={shelfNodeInformation[activeShelfNodeInfoTab].languageCode || ''}
                  onChange={event => handleShelfNodeInformationChange('languageCode', event.target.value)}
                  sx={{ mb: 1 }}
                />
                <Stack flexDirection="row" sx={{ mb: 1 }}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={classificationNumbers}
                    sx={{ width: '100%', mr: 1 }}
                    value={shelfNodeInformation[activeShelfNodeInfoTab].classificationNumberFrom || ''}
                    onChange={(_, value) => handleShelfNodeInformationChange('classificationNumberFrom', value)}
                    renderInput={params => <TextField {...params} label="Szakjel kezdő érték" />}
                    isOptionEqualToValue={() => true}
                  />
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={classificationNumbers}
                    sx={{ width: '100%' }}
                    value={shelfNodeInformation[activeShelfNodeInfoTab].classificationNumberTo || ''}
                    onChange={(_, value) => handleShelfNodeInformationChange('classificationNumberTo', value)}
                    renderInput={params => <TextField {...params} label="Szakjel záró érték" />}
                    isOptionEqualToValue={() => true}
                  />
                </Stack>
                <Stack flexDirection="row" sx={{ mb: 1 }}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={cutters}
                    sx={{ width: '100%', mr: 1 }}
                    value={shelfNodeInformation[activeShelfNodeInfoTab].cutterFrom || ''}
                    onChange={(_, value) => handleShelfNodeInformationChange('cutterFrom', value)}
                    renderInput={params => <TextField {...params} label="Jelzet kezdő érték" />}
                    isOptionEqualToValue={() => true}
                  />
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={cutters}
                    sx={{ width: '100%' }}
                    value={shelfNodeInformation[activeShelfNodeInfoTab].cutterTo || ''}
                    onChange={(_, value) => handleShelfNodeInformationChange('cutterTo', value)}
                    renderInput={params => <TextField {...params} label="Jelzet záró érték" />}
                    isOptionEqualToValue={() => true}
                  />
                </Stack>

                {classificationOrCutterErrorIndexes.includes(activeShelfNodeInfoTab) && (
                  <Box sx={{ color: '#ff0000', textAlign: 'center' }}>Szakjel vagy Jelzet páros megadása kötelező!</Box>
                )}

                <Button style={{ marginTop: '8px' }} variant="contained" onClick={saveShelfNode}>
                  Mentés
                </Button>
              </div>
            )}
          </Box>
        </Modal>

        {/* HIGHLIGHTED SHELF MODAL FROM */}
        <Modal
          open={highlightedShelfNodeModalOpen}
          onClose={handleHighlightedShelfNodeModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          <Box
            style={{
              width: '400px',
              borderRadius: '6px',
              backgroundColor: '#fff',
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              padding: '16px'
            }}
          >
            <Stack flexDirection="row" sx={{ mb: 2 }}>
              <Tabs
                value={activeHighlightedShelfNodeInfoTab}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                {highlightedShelfNodeInformation.map((currentHighlightedShelfNodeInfo, index) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <Tab
                      key={index}
                      onClick={() => setActiveHighlightedShelfNodeInfoTab(index)}
                      // className={`${formErrors.questions && formErrors.questions[index] === false ? 'invalid' : ''}`}
                      label={index + 1}
                      sx={{
                        color:
                          collectionErrorIndexesForHighlightedEntity.includes(index) ||
                          locationErrorIndexesForHighlightedEntity.includes(index) ||
                          highlightingErrorIndexesForHighlightedEntity.includes(index)
                            ? '#ff0000'
                            : '#808080'
                      }}
                    />
                    {highlightedShelfNodeInformation.length > 1 && (
                      <HighlightOffIcon
                        onClick={() => handleHighlightedShelfNodeInfoDelete(index, currentHighlightedShelfNodeInfo.id)}
                        sx={{ color: '#1565c0', width: '1rem', cursor: 'pointer' }}
                      />
                    )}
                  </div>
                ))}
              </Tabs>
              <Button sx={{ ml: 1 }} onClick={addMoreHighlightedShelfNodeInformation}>
                <AddCircleOutlineIcon />
              </Button>
            </Stack>

            {highlightedShelfNodeInformation.length > 0 && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <TextField
                  error={locationErrorIndexesForHighlightedEntity.includes(activeHighlightedShelfNodeInfoTab)}
                  required
                  id="outlined-required"
                  label="Elhelyezkedés"
                  value={highlightedShelfNodeInformation[activeHighlightedShelfNodeInfoTab].location || ''}
                  onChange={event => handleHighlightedShelfNodeInformationChange('location', event.target.value)}
                  sx={{ mb: 1 }}
                />
                <TextField
                  error={collectionErrorIndexesForHighlightedEntity.includes(activeHighlightedShelfNodeInfoTab)}
                  required
                  id="outlined"
                  label="Gyűjtemény"
                  value={highlightedShelfNodeInformation[activeHighlightedShelfNodeInfoTab].collection || ''}
                  onChange={event => handleHighlightedShelfNodeInformationChange('collection', event.target.value)}
                  sx={{ mb: 1 }}
                />
                <TextField
                  error={highlightingErrorIndexesForHighlightedEntity.includes(activeHighlightedShelfNodeInfoTab)}
                  required
                  id="outlined"
                  label="Kiemelés"
                  value={highlightedShelfNodeInformation[activeHighlightedShelfNodeInfoTab].highlighting || ''}
                  onChange={event => handleHighlightedShelfNodeInformationChange('highlighting', event.target.value)}
                  sx={{ mb: 1 }}
                />

                <Button style={{ marginTop: '8px' }} variant="contained" onClick={saveHighlightedShelfNode}>
                  Mentés
                </Button>
              </div>
            )}
          </Box>
        </Modal>

        <Menu
          id="simple-menu"
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
          style={{
            position: 'absolute',
            top: 0,
            left: 0
          }}
        >
          <MenuItem onClick={handleMenuRouteNodeClick}>{nodeTypes.ROUTE_NODE.label}</MenuItem>
          <MenuItem onClick={handleMenuStartNodeClick}>{nodeTypes.START_NODE.label}</MenuItem>
          <MenuItem onClick={handleMenuShelfNodeClick}>{nodeTypes.SHELF_NODE.label}</MenuItem>
          <MenuItem onClick={handleMenuHighlightedShelfNodeClick}>{nodeTypes.HIGHLIGHTED_SHELF_NODE.label}</MenuItem>
        </Menu>
        <Menu
          id="simple-menu"
          anchorEl={nodeMenuAnchorEl}
          keepMounted
          open={Boolean(nodeMenuAnchorEl)}
          onClose={handleNodeMenuClose}
          style={{
            position: 'absolute',
            top: 0,
            left: 0
          }}
        >
          {activeNode?.type === nodeTypes.START_NODE.name && (
            <MenuItem onClick={event => handleStartNodeEditClick(event)}>Szerkesztés</MenuItem>
          )}
          {activeNode?.type === nodeTypes.SHELF_NODE.name && (
            <MenuItem onClick={event => handleShelfNodeEditClick(event)}>Szerkesztés</MenuItem>
          )}
          {activeNode?.type === nodeTypes.HIGHLIGHTED_SHELF_NODE.name && (
            <MenuItem onClick={event => handleHighlightedShelfNodeEditClick(event)}>Szerkesztés</MenuItem>
          )}
          <MenuItem onClick={handleNodeDeleteMenuClick}>Törlés</MenuItem>
        </Menu>
        <Menu
          id="simple-menu"
          anchorEl={edgeMenuAnchorEl}
          keepMounted
          open={Boolean(edgeMenuAnchorEl)}
          onClose={handleEdgeMenuClose}
          style={{
            position: 'absolute',
            top: 0,
            left: 0
          }}
        >
          <MenuItem onClick={handleEdgeDeleteMenuClick}>Törlés</MenuItem>
        </Menu>

        <img
          alt={floorPlanId}
          src={`${baseUrl}/floor-plan-media/${floorPlanId}`}
          style={{ width: '800px' }}
          onLoad={handleImageLoad}
        />

        {imageDimensions && (
          <div
            style={{
              width: imageDimensions.width,
              height: imageDimensions.height,
              position: 'absolute',
              left: 0,
              top: 0
            }}
          >
            {clickPosition && (
              <div
                ref={rightClickDivRef}
                style={{
                  zIndex: 1000,
                  position: 'absolute',
                  left: `${clickPosition.x}%`,
                  top: `${clickPosition.y}%`,
                  width: '10px',
                  height: '10px',
                  transform: 'translate(-50%, -50%)'
                }}
              />
            )}

            {nodes.length > 0 &&
              nodes.map((node, index) => {
                if (
                  (node.type === nodeTypes.ROUTE_NODE.name && areRouteNodesVisible) ||
                  (node.type === nodeTypes.START_NODE.name && areStartNodesVisible) ||
                  ((node.type === nodeTypes.SHELF_NODE.name || node.type === nodeTypes.HIGHLIGHTED_SHELF_NODE.name) &&
                    areShelfNodesVisible)
                ) {
                  return (
                    <div
                      onClick={event => handleLeftClickOnNode(event, node)}
                      onContextMenu={event => handleRightClickOnNode(event, node)}
                      key={index}
                      style={{
                        zIndex: 1000,
                        position: 'absolute',
                        left: `${node.x}%`,
                        top: `${node.y}%`,
                        width: node.id === activeNode?.id ? '12px' : '10px',
                        height: node.id === activeNode?.id ? '12px' : '10px',
                        backgroundColor: nodeTypes[node.type].color,
                        borderRadius: '8px',
                        transform: 'translate(-50%, -50%)',
                        border: node.id === activeNode?.id ? '2px solid black' : '1px solid black',
                        fontSize: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'black',
                        fontWeight: 700
                      }}
                    >
                      {node.label}
                    </div>
                  );
                }
                return null;
              })}

            {lines.length > 0 && (
              <svg
                width={imageDimensions.width}
                height={imageDimensions.height}
                style={{ position: 'absolute', left: 0, top: 0 }}
              >
                {lines.map((line, index) => {
                  if (!isLineVisible(line.id)) {
                    return null;
                  }

                  return (
                    <line
                      onClick={handleLeftClickOnLine}
                      onContextMenu={event => handleRightClickOnLine(event, line)}
                      key={index}
                      x1={(imageDimensions.width / 100) * line.x1}
                      y1={(imageDimensions.height / 100) * line.y1}
                      x2={(imageDimensions.width / 100) * line.x2}
                      y2={(imageDimensions.height / 100) * line.y2}
                      strokeWidth={line.id === activeLine?.id ? '6' : '4'}
                      stroke="red"
                      strokeLinecap="square"
                    />
                  );
                })}
              </svg>
            )}
          </div>
        )}
      </div>

      <Snackbar open={isError} onClose={handleErrorSnackbarClose} severity="error">
        Valami hiba történt.
      </Snackbar>
    </div>
  );
};

export default PathDrawer;
