import { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Keycloak from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { Box } from '@mui/material';

import PrivateRoute from './containers/PrivateRoute';
import FloorPlans from './containers/FloorPlans';
import Cutters from './containers/Cutters';
import ClassificationNumbers from './containers/ClassificationNumbers';
import FloorPlan from './containers/FloorPlan';
import PathViewer from './containers/PathViewer';

import LoadingProvider from './contexts/LoadingContext';
import { AppConfigContext } from './contexts/AppConfigContext';

import { setApiHeader, clearApiHeader } from './resource/instances';
import { loadApplicationConfig } from './resource';

import './assets/styles.scss';

const App = () => {
  const [keycloakInstance, setKeycloakInstance] = useState();
  const [keycloakIsReady, setKeycloakIsReady] = useState(false);
  const [appConfig, setAppConfig] = useState();

  useEffect(() => {
    (async () => {
      const fetchedConfig = await loadApplicationConfig();

      setKeycloakInstance(new Keycloak(fetchedConfig.keycloak));
      setAppConfig(fetchedConfig);
    })();
  }, []);

  if (!keycloakInstance) {
    return null;
  }

  const onKeycloakEvent = (keyCloakEvent, error) => {
    if (keyCloakEvent === 'onReady') {
      setKeycloakIsReady(true);
    }
  };

  const onKeycloakTokens = keyCloakTokens => {
    if (keyCloakTokens.token) {
      setApiHeader(`Bearer ${keyCloakTokens.token}`);
    } else {
      clearApiHeader();
    }
  };

  return (
    <ReactKeycloakProvider authClient={keycloakInstance} onEvent={onKeycloakEvent} onTokens={onKeycloakTokens}>
      <AppConfigContext.Provider value={appConfig}>
        {keycloakIsReady && (
          <LoadingProvider>
            <Box sx={{ display: 'flex' }}>
              <BrowserRouter>
                <Routes>
                  <Route path="*" element={<PrivateRoute />}>
                    <Route path="floor-plan" element={<FloorPlans />} />
                    <Route path="cutter" element={<Cutters />} />
                    <Route path="classification-number" element={<ClassificationNumbers />} />
                    <Route path="floor-plan/:id" element={<FloorPlan />} />
                  </Route>
                  <Route path="viewer" element={<PathViewer />} />
                  <Route path='/' element={<Navigate to='/floor-plan' />} />
                </Routes>
              </BrowserRouter>
            </Box>
          </LoadingProvider>
        )}
      </AppConfigContext.Provider>
    </ReactKeycloakProvider>
  );
};

export default App;
