import { styled } from '@mui/system';
import { Typography } from '@mui/material';

const PageTitle = styled(props => (
  <Typography component="h2" variant="h4" {...props}>
    {props.title}
  </Typography>
))(({ theme }) => ({
  marginBottom: theme.spacing(3)
}));

export default PageTitle;
