import { Route, Routes } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

import { Box, Container, AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import Logout from '@mui/icons-material/Logout';

import FloorPlans from '../FloorPlans';
import FloorPlan from '../FloorPlan';
import Cutters from '../Cutters';
import ClassificationNumbers from '../ClassificationNumbers';
import Sidebar from '../../components/Sidebar';

const PrivateRoute = () => {
  const { keycloak } = useKeycloak();

  if (!keycloak.authenticated) {
    window.location.assign(keycloak.createLoginUrl());
  }

  const handleLogout = () => {
    keycloak.logout();
  };

  return (
    <>
      {/* HEADER */}
      <AppBar position="absolute" sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          {/* <Logo style={{ mr: 3 }} /> */}

          <Typography component="h1" variant="h6" color="inherit" sx={{ flexGrow: 1 }}>
            Könyvtári alaprajzok
          </Typography>
          <IconButton color="inherit" onClick={handleLogout}>
            <Logout />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* SIDEBAR */}
      <Sidebar />

      {/* CONTENT */}
      <Box
        component="main"
        sx={{
          backgroundColor: theme => theme.palette.grey[100],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto'
        }}
      >
        <Toolbar />

        <Container maxWidth="lg" sx={{ minHeight: 'calc(100vh - 148px)', my: 3 }}>
          <Routes>
            <Route path="/floor-plan" element={<FloorPlans />} />
            <Route path="floor-plan/:id" element={<FloorPlan />} />
            <Route path="/cutter" element={<Cutters />} />
            <Route path="/classification-number" element={<ClassificationNumbers />} />
            {/* <Redirect to="/employees" /> */}
          </Routes>
        </Container>

        {/* <Footer /> */}
      </Box>
    </>
  );
};

export default PrivateRoute;
