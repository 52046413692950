import { useState, useRef, useContext } from 'react';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import { Stack } from '@mui/material';

import { Typography, Button } from '@mui/material';

import { AppConfigContext } from '../../contexts/AppConfigContext';

import labels from '../../constants/labels';

const FileUpload = ({ file, onFileUpload, isError, floorPlanId, sx }) => {
  const { baseUrl } = useContext(AppConfigContext);
  const [preview, setPreview] = useState(floorPlanId && `${baseUrl}/floor-plan-media/${floorPlanId}`);
  const dropzoneRef = useRef();

  const handleFileChange = files => {
    const file = files[0];

    setPreview(URL.createObjectURL(file));
    onFileUpload(file);
  };

  const openFileDialog = () => {
    dropzoneRef.current.open();
  };

  return (
    <Dropzone
      ref={dropzoneRef}
      noClick
      noKeyboard
      onDrop={handleFileChange}
      accept={{
        'image/png': ['.png'],
        'image/jpeg': ['.jpeg', '.jpg']
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <Stack
          className={classNames('file-upload', { error: isError })}
          {...getRootProps()}
          spacing={2}
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <input {...getInputProps()} />
          {preview && <img alt="preview" src={preview} style={{ width: '200px' }} />}
          <Typography component="span" variant="span" style={{ marginTop: 0 }}>
            {file ? file.name : labels.dropImage}
          </Typography>
          <Button variant="contained" onClick={openFileDialog} sx={{ w: 4 }}>
            {labels.chooseImage}
          </Button>
        </Stack>
      )}
    </Dropzone>
  );
};

export default FileUpload;
