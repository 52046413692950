const labels = {
  createFloorPlan: 'Alaprajz létrehozása',
  name: 'Név',
  dropImage: 'Húzza ide a feltölteni kívánt képet',
  chooseImage: 'Kép választása',
  save: 'Mentés',
  cancel: 'Mégsem',
  delete: 'Törlés',
  floorPlanDelete: 'Alaprajz törlése',
  floorPlanDeleteConfirm: 'Biztosan törli az alaprajzot'
};

export default labels;
