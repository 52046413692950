import { forwardRef } from 'react';
import { Snackbar as MaterialSnackbar } from '@mui/material';

import MuiAlert from '@mui/material/Alert';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Snackbar = ({ open, onClose, severity, children }) => (
  <MaterialSnackbar open={open} autoHideDuration={6000} onClose={onClose}>
    <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
      {children}
    </Alert>
  </MaterialSnackbar>
);

export default Snackbar;
