import { useState } from 'react';

import { Stack, Button, TextField, Typography, Checkbox, FormControlLabel } from '@mui/material';

import Modal from '../Modal';
import FileUpload from '../FileUpload';

import labels from '../../constants/labels';

const FloorPlanUploadModal = ({ onClose, onSave, floorPlanForEdit, error }) => {
  const [name, setName] = useState(floorPlanForEdit?.name);
  const [file, setFile] = useState();
  const [isNameError, setNameError] = useState(false);
  const [isFileError, setFileError] = useState(false);
  const [deleteExistingNodes, setDeleteExistingNodes] = useState(false);

  const handleFloorPlanNameValueChange = value => {
    setNameError(false);
    setName(value);
  };

  const handleFileUpload = newFile => {
    setFileError(false);
    setFile(newFile);
  };

  const handleSave = () => {
    if (!name?.length) {
      setNameError(true);
    }

    if (!(file || floorPlanForEdit)) {
      setFileError(true);
    }

    if (name?.length && (file || floorPlanForEdit)) {
      onSave(file, name, deleteExistingNodes);
    }
  };

  return (
    <Modal>
      <Stack spacing={2}>
        <Typography component="h4" variant="h6" alignSelf="center">
          {labels.createFloorPlan}
        </Typography>
        <Stack>
          <TextField
            error={isNameError}
            className="text-input"
            required
            id="outlined-required"
            label={labels.name}
            value={name || ''}
            onChange={event => handleFloorPlanNameValueChange(event.target.value)}
          />
          <FileUpload
            file={file}
            onFileUpload={handleFileUpload}
            isError={isFileError}
            floorPlanId={floorPlanForEdit?.id}
          />
          {floorPlanForEdit && (
            <FormControlLabel
              control={
                <Checkbox
                  disabled={floorPlanForEdit && !file}
                  checked={deleteExistingNodes}
                  onChange={() => setDeleteExistingNodes(!deleteExistingNodes)}
                />
              }
              label="Törölje a meglévő pontokat és útvonalakat"
            />
          )}
        </Stack>

        {error && (
          <Typography component="span" variant="span" alignSelf="center" color="red">
            Valami hiba történt!
          </Typography>
        )}

        <Stack direction="row" justifyContent="space-evenly">
          <Button onClick={handleSave} variant="contained">
            {labels.save}
          </Button>
          <Button onClick={onClose}>{labels.cancel}</Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default FloorPlanUploadModal;
