import { api } from './instances';

// TODO: dynamic
const color = 'red';

const getFloorPlans = () => api.get('/floor-plans').then(response => response.data);

const getFloorPlan = id => api.get(`/floor-plans/${id}`).then(response => response.data);

const getFloorPlanWithPoints = id => api.get(`/floor-plans/${id}/with-points`).then(response => response.data);

const createFloorPlan = (file, name) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('name', name);
  formData.append('color', color);

  return api.post(`/floor-plans`, formData).then(response => response.data);
};

const updateFloorPlan = (id, file, name, deleteExistingNodes) => {
  const formData = new FormData();
  if (file) {
    formData.append('file', file);
  }
  formData.append('name', name);
  formData.append('color', color);
  const deleteExistingNodesAsString = [JSON.stringify(deleteExistingNodes)];
  formData.append('deleteExistingPoints', new Blob(deleteExistingNodesAsString, { type: 'application/json' }));

  return api.put(`/floor-plans/${id}`, formData).then(response => response.data);
};

const deleteFloorPlan = id => api.delete(`/floor-plans/${id}`);

const importCSV = (id, file) => {
  const formData = new FormData();
  formData.append('file', file);

  return api.post(`/floor-plans/${id}/entity-info/import`, formData);
};

const addRouteNode = async (floorPlanId, requestBody) => {
  const { data } = await api.post(`/floor-plans/${floorPlanId}/route-point`, requestBody);
  return data;
};

const deleteRouteNode = (floorPlanId, routePointId) => {
  return api.delete(`/floor-plans/${floorPlanId}/route-point/${routePointId}`);
};

const updateNodePosition = (floorPlanId, routePointId, coordinates) => {
  return api.put(`/floor-plans/${floorPlanId}/route-point/${routePointId}`, { coordinates });
};

const addStartNode = async (floorPlanId, requestBody) => {
  const { data } = await api.post(`/floor-plans/${floorPlanId}/start-point`, requestBody);
  return data;
};

const updateStartNodeInfo = (floorPlanId, startPointId, requestBody) => {
  return api.put(`/floor-plans/${floorPlanId}/start-point/${startPointId}`, requestBody);
};

const addEdge = async (floorPlanId, requestBody) => {
  const { data } = await api.post(`/floor-plans/${floorPlanId}/edge`, requestBody);
  return data;
};

const deleteEdge = (floorPlanId, edgeId) => {
  return api.delete(`/floor-plans/${floorPlanId}/edge/${edgeId}`);
};

const addShelfNode = async (floorPlanId, requestBody) => {
  const { data } = await api.post(`/floor-plans/${floorPlanId}/entity-info`, requestBody);
  return data;
};

const addHighlightedShelfNode = async (floorPlanId, requestBody) => {
  const { data } = await api.post(`/floor-plans/${floorPlanId}/highlighted-entity-info`, requestBody);
  return data;
};

const updateShelfNode = (floorPlanId, requestBody, routePointId) => {
  return api.put(`/floor-plans/${floorPlanId}/entity-info/route-point/${routePointId}`, requestBody);
};

const updateHighlightedShelfNode = (floorPlanId, requestBody, routePointId) => {
  return api.put(`/floor-plans/${floorPlanId}/highlighted-entity-info/route-point/${routePointId}`, requestBody);
};

const getShelfNodeInfoList = async (floorPlanId, routePointId) => {
  const { data } = await api.get(`/floor-plans/${floorPlanId}/entity-info/${routePointId}`);
  return data;
};

const getHighlightedShelfNodeInfoList = async (floorPlanId, routePointId) => {
  const { data } = await api.get(`/floor-plans/${floorPlanId}/highlighted-entity-info/${routePointId}`);
  return data;
};

export {
  getFloorPlans,
  getFloorPlan,
  getFloorPlanWithPoints,
  createFloorPlan,
  updateFloorPlan,
  deleteFloorPlan,
  importCSV,
  addRouteNode,
  deleteRouteNode,
  updateNodePosition,
  addStartNode,
  updateStartNodeInfo,
  addEdge,
  deleteEdge,
  addShelfNode,
  addHighlightedShelfNode,
  updateShelfNode,
  updateHighlightedShelfNode,
  getShelfNodeInfoList,
  getHighlightedShelfNodeInfoList
};
