import { useContext, useState, createContext } from 'react';

import Loading from '../components/Loading';

const LoadingContext = createContext();

const useLoading = () => {
  return useContext(LoadingContext);
};

const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const showLoading = () => setLoading(true);

  const hideLoading = () => setLoading(false);

  return (
    <LoadingContext.Provider value={{ showLoading, hideLoading }}>
      <Loading loading={loading} />
      {children}
    </LoadingContext.Provider>
  );
};

export { useLoading };
export default LoadingProvider;
