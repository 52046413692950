import { Backdrop, Box, Modal as ModalContainer, Fade } from '@mui/material';

const Modal = ({ onClose, children }) => (
  <ModalContainer
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open
    onClose={onClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500
    }}
  >
    <Fade in>
      <Box sx={{ boxShadow: 24, p: 4 }} className="floor-plan-modal">
        {children}
      </Box>
    </Fade>
  </ModalContainer>
);

export default Modal;
